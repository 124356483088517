<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box">
						<el-input clearable type="text" placeholder="姓名/手机号/员工号/证件号码" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">岗位状态</div>
					<div class="inputs_box">
						<el-select clearable v-model="gwzt_ac" placeholder="请选择">
							<el-option v-for="(item, index) in gwzt_list" :key="index" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
						<!-- 		<el-cascader placeholder="请选择" :options="bm_list" collapse-tags :props="props2" :show-all-levels="false"
							v-model="bm_list_ac" clearable></el-cascader> -->

						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
							@confirm="confirm_bumen_cascader_value" />

					</div>
				</div>
				<div class="search_flex">
					<div class="texts">岗位</div>
					<div class="inputs_box">
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
							v-model="gw_list_ac" clearable></el-cascader>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">用工类型</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.yglx" placeholder="请选择">
							<el-option v-for="(item, index) in yglx_list" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">职工类型</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.zglx" placeholder="请选择">
							<el-option v-for="(item, index) in zglx_list" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">职级</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.zjmc" placeholder="请选择" filterable>
							<el-option v-for="(item, index) in jz_list" :key="index" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">是否完善简历</div>
					<div class="inputs_box">
						<el-select clearable v-model="forms.is_wsjl" placeholder="请选择">
							<el-option v-for="(item, index) in jl_list" :key="index" :label="item.title" :value="item.state">
							</el-option>
						</el-select>
					</div>
				</div>
				<!-- <div class="search_flex">
                    <div class="texts">时间范围</div>
                    <div class="inputs_box">
                        <el-date-picker :editable="false" 
                        value-format="yyyy-MM-dd" :default-time="['00:00:00','23:59:59']" v-model="times" type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                    </div>
                </div> -->
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="handle_lzjl">
						离职人员记录
					</div>
					<div class="btns" @click="handle_rzdj">
						入职登记
					</div>
					<div class="btns" @click="handle_pldr">
						批量导入
					</div>
					<div class="btns" @click="handle_dc">
						导出
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="465">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column fixed prop="name" label="姓名" width="150">
						<template slot-scope="scope">
							<div slot="reference" class="name-wrapper" style="color: #2373C8;">
								{{ scope.row.name }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="depart_title" label="部门" width="300" :show-overflow-tooltip="false"></el-table-column>
					<el-table-column prop="level6" label="岗位" width="180"></el-table-column>
					<el-table-column prop="zglx" label="职工类型" width="100"></el-table-column>
					<el-table-column prop="mobile" label="手机号" width="200"></el-table-column>
					<el-table-column prop="gonghao" label="员工号" width="150"></el-table-column>
					<el-table-column prop="real_name" label="曾用名" width="100"></el-table-column>
					<el-table-column prop="id_type" label="证件类型" width="100"></el-table-column>
					<el-table-column prop="id_card" label="证件号码" width="200"></el-table-column>
					<el-table-column prop="id_card_time" label="身份证有效期" width="120"></el-table-column>
					<el-table-column prop="sex" label="性别"></el-table-column>
					<!-- <el-table-column prop="birthday" label="出生日期" width="120"></el-table-column> -->
					<el-table-column prop="birthday2" label="出生日期(档案)" width="120"></el-table-column>
					<el-table-column prop="nation" label="民族"></el-table-column>
					<el-table-column prop="native" label="籍贯" width="200"></el-table-column>
					<el-table-column prop="address" label="出生地" width="200"></el-table-column>
					<el-table-column prop="hk_address" label="户口所在地" width="200"></el-table-column>
					<el-table-column prop="hkxz" label="户口性质"></el-table-column>
					<el-table-column prop="guoji" label="国籍或地区" width="120"></el-table-column>
					<el-table-column prop="jrjt_time" label="进入集团时间" width="120"></el-table-column>
					<el-table-column prop="jrqy_time" label="进入本企业时间" width="120"></el-table-column>
					<el-table-column prop="jiankang" label="健康状况"></el-table-column>
					<el-table-column prop="hyzt" label="婚姻状态"></el-table-column>
					<el-table-column prop="shenfen" label="人员身份"></el-table-column>
					<el-table-column prop="gqqjbs" label="归侨侨眷标识" width="120"></el-table-column>
					<el-table-column prop="gz_address" label="工作地点" width="200"></el-table-column>
					<el-table-column prop="zywh" label="职业危害/特殊工种" width="250"></el-table-column>
					<el-table-column prop="fzjrbs" label="复转军人标识" width="180"></el-table-column>
					<el-table-column prop="zyzc" label="熟悉专业有何专长" width="250"></el-table-column>
					<el-table-column prop="ldbwy" label="两代表一委员经历" width="250"></el-table-column>
					<el-table-column prop="gz_time" label="参加工作时间" width="180"></el-table-column>
					<el-table-column prop="shgl" label="社会工龄扣除（月）" width="180"></el-table-column>
					<el-table-column prop="jtglkcy" label="集团工龄扣除（月）" width="180"></el-table-column>
					<el-table-column prop="zz_time" label="转正时间" width="180"></el-table-column>
					<el-table-column prop="jrjtryly" label="进入集团人员来源" width="180"></el-table-column>
					<el-table-column prop="rzyx_time" label="任职生效时间" width="180"></el-table-column>
					
					<!--
					<el-table-column prop="level2" label="岗位_二级组织" width="180"></el-table-column>
					<el-table-column prop="level3" label="岗位_三级组织" width="180"></el-table-column>
					<el-table-column prop="level4" label="岗位_四级组织" width="180"></el-table-column>
					<el-table-column prop="level5" label="岗位_五级组织" width="180"></el-table-column>
					<el-table-column prop="level6" label="岗位_六级组织" width="180"></el-table-column> -->
					<!-- <el-table-column prop="name" label="岗位_七级组织" width="180"></el-table-column>
                    <el-table-column prop="name" label="岗位_八级组织" width="180"></el-table-column>
                    <el-table-column prop="name" label="岗位_九级组织" width="180"></el-table-column>
                    <el-table-column prop="name" label="岗位_十级组织" width="180"></el-table-column> -->


					

					<el-table-column prop="zjxlfa" label="职级_序列职级方案" width="180"></el-table-column>
					<el-table-column prop="zjxlmc" label="职级_序列名称" width="180"></el-table-column>
					<el-table-column prop="zjmc" label="职级_职级名称" width="180"></el-table-column>
					<el-table-column prop="yglx" label="用工类型" width="180"></el-table-column>
					<el-table-column prop="gwzt" label="岗位状态" width="180"></el-table-column>
					<el-table-column prop="bdsw" label="变动事务" width="180"></el-table-column>
					<el-table-column prop="bdyy" label="变动原因" width="180"></el-table-column>
					<el-table-column prop="sort" label="排序码" width="180"></el-table-column>
					<el-table-column prop="jjkh_bank" label="奖金卡银行" width="180"></el-table-column>
					<el-table-column prop="jjkh" label="奖金卡号" width="180"></el-table-column>
					<el-table-column prop="gzkh_bank" label="工资卡银行" width="180"></el-table-column>
					<el-table-column prop="gzkh" label="工资卡号" width="180"></el-table-column>

					<!-- 操作模块   -->
					<!-- fixed="right"  -->
					<el-table-column label="操作" fixed="right" width="100">
						<template slot-scope="scope">
							<el-dropdown @command="handleCommand">
								<span class="el-dropdown-link">
									<div class="btn_img">
										<img src="../../assets/img/employee/caozuo.png" alt="">
									</div>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="{ key: 1, row: scope.row }">查看详情</el-dropdown-item>
									<el-dropdown-item :command="{ key: 2, row: scope.row }">编辑信息</el-dropdown-item>
									<el-dropdown-item :command="{ key: 3, row: scope.row }"
										v-if="scope.row.gwzt!='离职'">离职操作</el-dropdown-item>
									<el-dropdown-item :command="{ key: 4, row: scope.row }"
										v-if="scope.row.is_del!=1">加入黑名单</el-dropdown-item>
									<el-dropdown-item :command="{ key: 5, row: scope.row }">添加简历</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<Employee_Information_add ref="Employee_Information_add"></Employee_Information_add>
		<!-- 导入信息 -->
		<up_files ref="up_files" @handle_up_end="handle_up_end"></up_files>
		<Employee_Information_dimission ref="Employee_Information_dimission"  @handle_get_list="handle_get_list"></Employee_Information_dimission>
		<Employee_Information_Blacklist ref="Employee_Information_Blacklist" @handle_get_list="handle_get_list">
		</Employee_Information_Blacklist>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	import Employee_Information_add from "@/components/Employee_management/Employee_Information_add.vue"; //导入员工信息
	import Employee_Information_dimission from "@/components/Employee_management/Employee_Information_dimission.vue"; //离职
	import Employee_Information_Blacklist from "@/components/Employee_management/Employee_Information_Blacklist.vue"; //黑名单


	export default {
		components: {
			page,
			Employee_Information_add,
			Employee_Information_dimission,
			Employee_Information_Blacklist,
		},
		data() {
			return {
				bumen_position_info: {
					position: '',
					bumen_titles: '',
				},

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '', //关键字
					gwzt: '', //岗位状态
					yglx: '', //用工类型
					zjmc: '', //职级
					is_wsjl: '', //是否完善简历 0 全部 1未完成 2 已完成
					is_del: 0, //1 黑名单
					zglx:'',//职工类型
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
				item_row: {}, //选中的

				props: {
					multiple: false,
					value: 'id',
					label: 'title'
				},
				props2: {
					checkStrictly: true,
					multiple: false,
					value: 'title',
					label: 'title'
				},

				//选中的岗位
				gw_list_ac: [],
				//岗位列表
				gw_list: [],
				//部门列表
				bm_list: [],
				//选中的部门
				bm_list_ac: [],
				//简历状态
				jl_list: [
					// 0 全部 1未完成 2 已完成

					{
						title: '未完成',
						state: '1',
					},
					{
						title: '已完成',
						state: '2',
					},
				],
				gwzt_ac: '',
				//岗位状态
				gwzt_list: [{
						title: '全部',
						value: '',
					},
					{
						title: '在岗',
						value: '在岗',
					},
					{
						title: '离职',
						value: '离职',
					},
					{
						title: '黑名单',
						value: '1',
					}
				],
				//职级
				jz_list: [

				],
				zglx_list:[],//职工类型
				yglx_list: [{
						title: '全部',
						value: '',
					},
					{
						title: '劳务派遣',
						value: '劳务派遣',
					},
					{
						title: '劳动合同制',
						value: '劳动合同制',
					}
				],
				file_url:'',//模板文件

			};
		},

		computed: {},
		watch: {
			bm_list(value) {
				this.$log('watch bm_list', value)
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			this.$nextTick(()=>{
				this.get_config()
				this.get_list()
				this.get_gw_list()
			})
		},
		mounted() {

		},
		methods: {
			get_config() {
				this.yglx_list = this.get_vuex_type(11); //用工类型
				this.jz_list = this.get_vuex_type(12);
				this.zglx_list = this.get_vuex_type(86);
			},


			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},

			//上传回调
			handle_up_end(info) {
				console.log(info)
				
				this.$api("importUser", {
					file_path: info.file_path,
				}, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.get_list()
					}
				});
			},
			// 离职人员记录
			handle_lzjl() {
				this.$router.push({
					path: '/Employee_Information_dimission',
				})
			},
			// 入职登记
			handle_rzdj() {
				this.$router.push({
					path: 'Employee_Information_add',

				})
			},
			// 批量导入
			handle_pldr() {
				// this.$refs.Employee_Information_add.handle_open()
				let obj={
					file_url:this.file_url,
				}
				this.$refs.up_files.handle_open(obj)

			},
			// 导出
			handle_dc() {
				console.log(this.forms)
				// this.loading = true
				if (this.gwzt_ac == 1) {
					this.forms.is_del = 1
					this.forms.gwzt = ''
				} else {
					this.forms.is_del = 0
					this.forms.gwzt = this.gwzt_ac
				}
				let level6=''
				if (this.gw_list_ac.length != 0) {
					level6=this.gw_list_ac[this.gw_list_ac.length-1]
				}
				let ids_list=[]
				this.check_list.forEach((e)=>{
					ids_list.push(e.id)
				})
				let ids=ids_list.join(',')
				window.open(`http://jinniu.dx.hdapp.com.cn/api/exportUser?token=${localStorage.getItem('token')}&keyword=${this.forms.keyword}&gwzt=${this.forms.gwzt}&yglx=${this.forms.yglx}&zjmc=${this.forms.zjmc}&is_wsjl=${this.forms.is_wsjl}&is_del=${this.forms.is_del}&page=1&limit=1000000&depart_title=${this.bumen_position_info.bumen_titles || ''}&ids=${ids}`)
				// this.$api("exportUser", {
				// 	// mobile:this.mobile
				// 	...this.forms,
				// 	page: 1,
				// 	limit: 1000000,
				// 	depart_title: this.bumen_position_info.bumen_titles || ''
				// }, "get").then((res) => {
				// 	this.loading = false
				// 	if (res.code == 200) {
				// 		this.count = res.data.count
				// 		this.list = res.data.list
				// 	} else {
				// 		alertErr(res.msg)
				// 	}
				// });
			},

			//操作回调
			handleCommand(e) {
				// console.log(e)
				this.item_row = e.row
				let i = e.key
				switch (i) {
					//查看详情
					case 1:
						this.handle_ckxq()
						break;
						// 编辑信息
					case 2:
						this.handle_bjxx()
						break;
						// 离职操作
					case 3:
						this.handle_lzcz()
						break;
						// 加入黑名单
					case 4:
						this.handle_jrhmd()
						break;
						// 添加简历
					case 5:
						this.handle_tjjl()
						break;
				}
			},
			//查看详情
			handle_ckxq() {
				this.$router.push({
					path: '/Employee_Information_detial',
					query: {
						id: this.item_row.id
					}
				})
			},
			//编辑信息
			handle_bjxx() {
				this.$router.push({
					path: '/Employee_Information_add',
					query: {
						id: this.item_row.id
					}
				})

			},
			//离职操作
			handle_lzcz() {
				this.$refs.Employee_Information_dimission.handle_open(this.item_row)
			},
			//加入黑名单
			handle_jrhmd() {
				this.$refs.Employee_Information_Blacklist.handle_open(this.item_row)

			},
			//添加简历
			handle_tjjl() {
				this.$router.push({
					path: '/Employee_Information_resume',
					query: {
						id: this.item_row.id
					}
				})

			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				if (this.gwzt_ac == 1) {
					this.forms.is_del = 1
					this.forms.gwzt = ''
				} else {
					this.forms.is_del = 0
					this.forms.gwzt = this.gwzt_ac

				}
				let level6=''
				if (this.gw_list_ac.length != 0) {
					level6=this.gw_list_ac[this.gw_list_ac.length-1]
				}
				this.$api("getUserList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					level6:level6,
					depart_title: this.bumen_position_info.bumen_titles || ''
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
						this.file_url=res.data.moban
					} else {
						alertErr(res.msg)
					}
				});
			},


			//获取岗位
			get_gw_list() {
				this.query_all_department()

				// return

				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						let list2 = []
						let data = res.data
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
							if (e.is_gw != 1) {
								this.$set(e, 'children', [])
							}
						})
						console.log('最高登记', level)


						for (let i = 1; i <= level; i++) {
							let level_list = []
							let level_list2 = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}
								if (e.level == i && e.is_gw != 1) {
									level_list2.push(e)
								}

							})
							list.push(level_list)
							list2.push(level_list2)
						}
						console.log('列表1', list)
						console.log('列表2', list2)

						// 总列表  最后一位的下标
						this.gw_list_data(list, list.length - 1)
						this.bm_list_data(list2, list2.length - 1)

						this.$log('梓豪部门数据', list2)


					} else {
						alertErr(res.msg)
					}
				});
			},



			//所有组织架构
			query_all_department() {
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					if (res.code == 200) {
						let list = res.data;
						this.handle_cate_list(list)
					}
				});
			},
			//处理所有分类数据
			handle_cate_list(list) {
				console.log('处理部门数据')
				this.$log('获取分类分组 groupList', this.groupList)
				this.$log('获取分类选中 groupSelectList', this.groupSelectList)
				let cate_level_map = {
					// 1: [], 
					// 2: [], 
				};
				list.forEach(v => {
					let {
						level
					} = v;
					if (!cate_level_map[level]) {
						cate_level_map[level] = []
					}
					cate_level_map[level].push(v)
				})

				this.cate_flat_list = list;
				this.cate_level_map = cate_level_map;
				this.$log('cate_level_map', cate_level_map)
			},





			//处理岗位列表
			gw_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.is_gw == 1) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.gw_list = list[0]
					return
				}
				this.gw_list_data(list, index2)
			},
			bm_list_data(lists, indexs) {
				console.log('列表111', lists)
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.is_gw == 1) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的部门', list)
					this.bm_list = list[0]
					return
				}
				this.bm_list_data(list, index2)
			},
			get_jz_list() {
				// this.loading = true
				// this.$api("enumsetting", {
				// 	flag: '职级'
				// }, "post").then((res) => {
				// 	console.log('所有职位', res)
				// 	this.loading = false
				// 	if (res.code == 200) {
				// 		this.jz_list = res.data

				// 	} else {
				// 		alertErr(res.msg)
				// 	}
				// });

				let list = this.$store.state.vuex_type_list

			},




			confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
				this.$log('勾选部门数据', value)
				let id_arr = value;
				let departIds = id_arr.join(',')
				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					position: departIds,
					bumen_titles: bumen_titles
				}



				//岗位
				this.position_list = this.cate_flat_list.filter(v => v.parent_id == id_arr[id_arr.length - 1])

				this.$log('岗位列表 position_list', this.position_list)
			},



		},


	};
</script>

<style scoped lang="less">
	.page_box {
		// min-height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 9px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 60px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					margin-right: 48px;
					margin-bottom: 20px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
						width: 100px;
					}

					.inputs_box {
						width: 236px;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}

						/deep/.el-select {
							width: 236px;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}

				.search_flex:nth-child(4n) {
					margin-right: 0;
				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-left: 16px;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>