<template>
    <div class="dialog_box">
        <el-dialog title="加入黑名单" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false">
            <div class="form_box">
                 <div class="text_box">加入黑名单后，将不在参与考勤和薪资计算</div>
                <div class="form_btns">
                    <div class="btns2" @click="handle_close">取消</div>
                    <div class="btns1" @click="handle_ends">确定</div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            form:{},

        };
    },
    methods: {

        //打开
        handle_open(item) {
            this.form=item
            console.log(item)
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
            this.loading = true
            this.$api("delUser", {
               id:this.form.id
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_list");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 580px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
        .text_box{
            text-align: center;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #999999;

        }
       
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 40px;
            .btns1{
                width: 91px;
                height: 38px;
                background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                margin-left: 22px;

            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
            }
        }
    }
}
</style>